<template>
    <NewspaperPublish 
        v-model:selected-newspapers="modelValue.selectedNewspapers" 
        v-model:news-type-selected="modelValue.newsTypeSelected"
        v-model:ignore-newspapers="modelValue.ignoreNewspapers"
    />
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useNotification } from '@kyvg/vue3-notification';
import NewspaperPublish from '@/components/newspaper-publish/components/NewspaperPublish.vue';

const modelValue = defineModel();

const { t } = useI18n({});
const { notify }  = useNotification();

const isValid = () => {
    // Check external news
    let publishNewsPaperType = [];
    modelValue.value.selectedNewspapers.forEach( (newspaper_id) => {
        publishNewsPaperType.push({
            newspaper_id: newspaper_id,
            type: modelValue.value.newsTypeSelected[newspaper_id]
        });
    });
    const linked = publishNewsPaperType.filter( (item) => {
        return item.type == 'linked';
    });
    if(linked.length > 0) {
        const master = publishNewsPaperType.filter ( (item) => {
            return item.type == 'master';
        });
        if(master.length !== 1) {
            notify({
                title: 'Errore',
                text: t('wrong-linked-news'),
                type: 'danger'
            });
            return false;
        }
    }
    console.log("publishNewsPaperType", publishNewsPaperType);
    return true;
}

defineExpose({
    isValid
})

</script>