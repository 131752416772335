<template>
    <h2>Giornali di pubblicazione:</h2>
    <div v-if="ignoreNewspapers?.length > 0" class="mt-2">
        <em>
            La notizia è pubblicata anche su {{ ignoreNewspapers.length  }} giornali a cui non si ha accesso!<br/>
            Queste pubblicazioni non verranno modificate.
        </em>
    </div>
    <template v-if="preferredNewspapers.length > 0">
    <div class="mt-2 form-group">
        <div v-for="newspaper_id in preferredNewspapers" class="row">
            <div class="icheck-primary col-7 col-sm-5">
                <input type="checkbox" class="form-check-input"  name="newspaper[]" :id="`pref-${newspaper_id}`" :value="newspaper_id"  v-model="selectedNewspapers" />
                <label class="form-check-label" :for="`pref-${newspaper_id}`">{{ newsPaperStore.getNewspaperName(newspaper_id) }}</label>
            </div>
            <div class="col-5 col-sm-7">
                <select class="form-control form-control-sm" v-model="newsTypeSelected[`${newspaper_id}`]">
                    <option value="normal"></option>
                    <option v-for="newsTypeOption in newsTypeOptions" :value="newsTypeOption.id">{{ newsTypeOption.label }}</option>
                </select>
            </div>
        </div>
    </div>
    <hr>
    </template>
    <div ref="publish_newspaper" class="mt-3 form">
        <ul class="nav-sidebar newspaper-list">
            <li class="nav-item mb-2" v-for="(backend, backend_id) in newsPaperStore.newspaper" :class="backendItemClass(backend_id)" @click="(event) => toggleBackend(event, backend_id)">
                <i class="fas fa-book"></i> <strong style="cursor:pointer;">{{ backendStore.getBackendName(backend_id) }}</strong>
                <ul class="nav nav-treeview" @click="(e) => e.stopPropagation()">
                    <li v-for="(newspaper, newspaper_id) in backend" class="nav-item row">
                        <div class="icheck-primary col-7 col-sm-5">
                            <input type="checkbox" class="form-check-input" :id="`${backend_id}-${newspaper.uid}`"  name="newspaper[]" :value="`${backend_id}-${newspaper.uid}`" v-model="selectedNewspapers"  />
                            <label class="form-check-label" :for="`${backend_id}-${newspaper.uid}`">{{ newspaper.name }}</label>
                        </div>
                        <div class="col-5 col-sm-7">        
                            <select class="form-control form-control-sm" v-model="newsTypeSelected[`${backend_id}-${newspaper.uid}`]">
                                <option value="normal"></option>
                                <option v-for="newsTypeOption in newsTypeOptions" :value="newsTypeOption.id">{{ newsTypeOption.label }}</option>
                            </select>
                        </div>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<style>
.nav-sidebar.newspaper-list {
    list-style: none;
    padding: 0;
}
</style>

<script setup>
import { ref, computed, onBeforeMount } from 'vue';
import { useAuthStore, useNewsPaperStore, useBackendStore } from '@/stores';


const authStore = useAuthStore();
const backendStore = useBackendStore();
const newsPaperStore = useNewsPaperStore();

const selectedNewspapers = defineModel('selectedNewspapers');
const newsTypeSelected = defineModel('newsTypeSelected');
const ignoreNewspapers = defineModel('ignoreNewspapers');

const backendItemStatus = ref({});

// Open Backend if a newspaper is selected
/*
selectedNewspapers.value.forEach( (item, index) => {
    let backendId = item.split('-')[0];
    backendItemStatus.value[backendId] = true;
});

onBeforeMount( () => {

})
*/

const newsTypeOptions = [{
    id: 'linked',
    label: 'Linkata'
}, {
    id: 'master',
    label: 'Principale'
}];


const preferredNewspapers = computed( () => {
  let newspapers = [];
  if(authStore.user?.preferences?.newspapers?.length > 0 && newsPaperStore.newspaper && Object.keys(newsPaperStore.newspaper).length > 0) {
    const filtered = authStore.user.preferences.newspapers.filter( (newspaper_id) => {
      let parts = newspaper_id.split('-');
      if(parts[0] in newsPaperStore.newspaper) {
        for(const [name, newspaper] of Object.entries(newsPaperStore.newspaper[parts[0]])) {
          if(newspaper.uid == parts[1]) {
            return true;
          }
        }
      }
      return false;
    });
    newspapers = filtered;
  }
  return newspapers;
});

const toggleBackend = (event, backend_id) => {
    backendItemStatus.value[backend_id] = !backendItemStatus.value[backend_id];
}

const backendItemClass = (backend_id) => {
    let cssClass = "menu-open";
    if(preferredNewspapers.value.length > 0) {
        if(!backendItemStatus.value[backend_id]) {
            cssClass = '';
        }
    }
    return cssClass;
}
</script>