<script setup>
import { getCurrentInstance, watch, ref, computed } from 'vue'
import Lazy from "../Lazy.vue";
import FolderTree from "./FolderTree.vue"
import { useAuthStore } from '../../stores';

const authStore = useAuthStore();

const isOpening = ref(false);
const isClosing = ref(false);
const childMounting = ref(0);

const emit = defineEmits(["folderselect", "opening", "folderIsSelected"]);

const props = defineProps({
    'filemount': Object
});

const filemountLastPath = computed({
    get() {
        return authStore.user.preferences.lastPreferences.images.selectedArchive;
    },
    set(value) {
        if(authStore.user.preferences.lastPreferences.images.selectedArchive != value) {
            authStore.user.preferences.lastPreferences.images.selectedArchive = value;
            authStore.updatePreferences(authStore.user);
        }
    }
});

const childOpening = (status) => {
    emit("opening", status);
};

const childBeforeMount = async () => {
    childMounting.value++;
};
        
const childMounted = async () => {
    childMounting.value--;
    if(childMounting.value == 0) {
        isOpening.value = false;
        emit("opening", isOpening.value);
    }
};

const toggleStatus = () => {
    if(!props.filemount.opened) {
        isOpening.value = true;
        emit("opening", isOpening.value);
    } 
    
    props.filemount.opened = !props.filemount.opened;
};

const isSelected = (id) => {
    const result = id == filemountLastPath.value;
    if(result) {
        emit("folderIsSelected", id);
    }
    return result;
};

const folderSelect = async (path) => {
    // From CategoryTree
    emit("folderselect", path); 
};


const isOpened = (filemount) => {
    let traversed = false;
    if(filemountLastPath.value) {
        traversed = filemountLastPath.value.startsWith(filemount.id) && filemountLastPath.value !== filemount.id;
        if(traversed) {
            filemount.opened = true;
        }
    }
    return filemount.opened;
}

watch(() => authStore.user.preferences.hideArchiveTree, (hideArchiveTree) => {
    const instance = getCurrentInstance();
    instance.proxy.forceUpdate();
});
</script>


<template>
    <li v-if="props.filemount" :class="(isSelected(props.filemount.id) ? 'filemount-item selected' : 'filemount-item')" >
        <i v-if="props.filemount.root?.length > 0 && !authStore.user.preferences.hideArchiveTree" :class="(isOpening.value || isClosing.value ? 'fas fa-spinner fa-spin' : (isOpened(props.filemount) ? 'far fa-folder-open mr-1' : 'far fa-folder-closed mr-1'))"  @click="toggleStatus(props.filemount)"></i>
        <i v-else class="far fa-folder mr-1"></i>
		<span class=" filemount" @click="folderSelect(props.filemount.id)">{{ props.filemount.name }}</span>
        <template v-if="(isOpened(props.filemount) && !authStore.user.preferences.hideArchiveTree)">
            <Lazy><FolderTree ref="tree" @beforemount="childBeforeMount()" @mounted="childMounted(props.filemount)" :filemount_id="props.filemount.id" :baseUrl="props.filemount.base_url" :parentPath="props.filemount.id" :folders="props.filemount.root" :level=1 :opened=isOpened(props.filemount) @folderselect="folderSelect" @opening="(status) => childOpening(status)" /></Lazy>
        </template>
    </li>
</template>
