<template>
    <div class="modal fade" ref="refModal"  tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" >
        <div class="modal-dialog" :class="props.size" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" :class="props.bodyClass">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.modal-xxl {
    max-width: 90vw;
}
</style>
<script setup>

import { onMounted, ref, watch } from 'vue';

const props = defineProps({
    show: {
        "type": Boolean,
        "default": false
    },
    size: {
        "type": String,
        "default": 'modal-xl'
    },
    bodyClass: {
        "type": String,
        "default": ''
    }
});
const emit = defineEmits(['modal-action']);

const refModal = ref(null);
const modal = ref(null);

const cancel = () => {
    close();
}

const close = () => {
    emit('modal-action', 'close');
}

onMounted( () => {
    modal.value = new bootstrap.Modal(refModal.value);
    modal.value._config.backdrop = "static";
    modal.value._config.keyboard = true;
    $(modal.value._element).on("hide.bs.modal", cancel);
});

watch( () => props.show, (newVal, oldVal) => {
    if(newVal) {
        modal.value.show();
    } else {
        modal.value.hide();
    }
})
</script>