import { defineStore } from 'pinia';
import { getTransitionRawChildren, ref } from 'vue';
import { fetchWrapper } from '../_helpers/fetch-wrapper';


const baseUrl = `${import.meta.env.VITE_API_URL}/journal`;


export const useFilemountStore = defineStore({
    id: 'filemount',
    state: () => ({
        filemount: ref({}),
        last_fetch: null,
    }),
    actions: {
        async renameFile(filemount_id, file_id, new_filename) {
            return new Promise( (resolve, reject) => {
                fetchWrapper.post(`${baseUrl}/filemount/${filemount_id}/file/${file_id}/rename`, {"data": new_filename})
                    .then( (response) => {
                        resolve();
                    })  
                    .catch( (reason) => {
                        reject(reason);
                    })
            });
        },
        async deleteFile(filemount_id, file_id) {
            return new Promise( (resolve, reject) => {
                fetchWrapper.delete(`${baseUrl}/filemount/${filemount_id}/file/${file_id}`)
                    .then( (response) => {
                        resolve();
                    })
                    .catch( (reason) => {
                        reject("Errore nella eliminazione del file: '" + reason + "'");
                    })
            });
        },
        async createFolder(path, folderName) {
            const myPromise = new Promise((resolve, reject) => {
                let parts = path.split("/");
                let filemount_id = parts.shift();
                path = parts.join("/");
                let resp = fetchWrapper.post(`${baseUrl}/filemount/${filemount_id}/folders?base=${path}`, {"data": {"folder": folderName}})
                .then( res01 => {
                    fetchWrapper.get(`${baseUrl}/filemount/${filemount_id}/folders`)
                    .then( data => {
                        let [backend_id, mount_id] = filemount_id.split("-");
                        Object.keys(this.filemount[backend_id]).forEach((key, index) => {
                            if(this.filemount[backend_id][key].id == filemount_id) {
                                this.filemount[backend_id][key].root = data['data'][filemount_id];
                            }
                        });
                        
                        resolve();
                    })
                }).catch((reason) => {
                    reject("Errore nella creazione della cartella '" + folderName + "'");
                });
            });
            return myPromise;
        },
        async fetchFilemount(hideFolders = false) {
            let myPromise;
            if(this.last_fetch == null) {
                myPromise = new Promise((resolve, reject) => {
                    fetchWrapper.get(`${baseUrl}/filemount`).then( data => {
                        let filemounts = {};
                        for( let [rel_id, backend] of Object.entries(data['data']) ) {
                            if('filemounts' in backend && backend['filemounts']) {
                                for(let [filemount_id, filemount] of Object.entries(backend['filemounts'])) {
                                    //giornali[`${newspaper.backendId}-${newspaper_id}`] = newspaper;
                                    if(!(backend.backend_id in filemounts)) {
                                        filemounts[backend.backend_id] = new Object();
                                    }
                                    filemounts[backend.backend_id][filemount.name] = {
                                        id: backend.backend_id + '-' + filemount.id,
                                        name: filemount.name,
                                        root: null,
                                        baseUrl: backend.base_url,
                                        backendId: backend.backend_id,
                                        isOpening: false,
                                        childMounting: 0,
                                    }
                                }
                                let sorted = Object.keys(filemounts[backend.backend_id])
                                .sort()
                                .reduce(function (acc, key) {
                                    acc[key] = filemounts[backend.backend_id][key];
                                    return acc;
                                }, {});
                                filemounts[backend.backend_id] = sorted;
                            }
                        };
    
                        this.filemount = filemounts;
                        //this.filemount = filemount['data'];
                        let res = []
                        if(!hideFolders) {
                            let waiters = [];
                            for( let [backend_id, backend] of Object.entries(this.filemount) ) {
                                for( let [index, el] of Object.entries(backend) ) {
                
                                    waiters.push(fetchWrapper.get(`${baseUrl}/filemount/${el.id}/folders`).then( data => {
                                        el.root = data['data'][el.id];
                                    }));
                                    
                                }
                            }
                            Promise.all(waiters).then( el => {
                                this.last_fetch = Date.now();
                                resolve();
                            });
                        } else {
                            this.last_fetch = Date.now();
                            resolve();
                        }

                    });
                });
            } else {
                myPromise = new Promise((resolve, reject) => {
                    resolve();
                });
            }

            return myPromise;
        },
        async uploadFile(filemountPath, fileReq) {
            let parts = filemountPath.split("/");
            let filemount_id = parts.shift();
            let path = parts.join("/");
            this.uploadErrors = [];
            return fetchWrapper.post(`${baseUrl}/filemount/${filemount_id}/upload?base=${path}`, fileReq).catch(error => {
                this.uploadErrors.push(error);
            })
        },
        async fetchImages(path, pagenum=1) {
            let parts = path.split("/");
            let filemount_id = parts.shift();
            path = parts.join("/");
            const dam_images = await fetchWrapper.get(`${baseUrl}/filemount/${filemount_id}/images?page[size]=10&path=${path}&page[number]=${pagenum}`);
            //const url = new URL(dam_images['data'][filemount_id]['links']['self']);

            return {
                filemount_id: filemount_id,
                images: dam_images['data'][filemount_id]['data'],
                baseUrl: dam_images['data'][filemount_id]['base_url'],
                totalPages: dam_images['data'][filemount_id]['meta']['totalPages'],
                currentPage: pagenum
            };
        },
        async searchImages(path, searchTerm, offset=0, limit=10, order='tstamp', sort='desc') {
            let parts = path.split("/");
            let filemount_id = parts.shift();
            path = parts.join("/");
            console.log("filemountStore.searchImages", {
                path: path,
                searchTerm: searchTerm,
                offset: parseInt(offset) + 1,
                limit: limit
            });
            let pagenum = Math.ceil( (parseInt(offset) + 1) / parseInt(limit));
            let url = `${baseUrl}/filemount/${filemount_id}/images?page[size]=${limit}&path=${path}&page[number]=${pagenum}&sorting[field]=${order}&sorting[order]=${sort}`;
            if(searchTerm) {
                url = `${url}&search=${searchTerm}`;
            }
            const dam_images = await fetchWrapper.get(url);
            //const url = new URL(dam_images['data'][filemount_id]['links']['self']);

            return {
                filemount_id: filemount_id,
                images: dam_images['data'][filemount_id]['data'],
                baseUrl: dam_images['data'][filemount_id]['base_url'],
                totalPages: dam_images['data'][filemount_id]['meta']['totalPages'],
                totalResult: dam_images['data'][filemount_id]['meta']['totalResult'],
                currentPage: pagenum
            };
        },
        async searchTypesenseImages(path, searchTerm, offset=0, limit=10, order='tstamp', sort='desc') {
            let parts = path.split("/");
            let filemount_id = parts.shift();
            path = parts.join("/");
            console.log("filemountStore.searchTypesenseImages", {
                path: path,
                searchTerm: searchTerm,
                offset: parseInt(offset) + 1,
                limit: limit
            });
            let pagenum = Math.ceil( (parseInt(offset) + 1) / parseInt(limit));
            let url = `${baseUrl}/filemount/${filemount_id}/search/elastic?page[size]=${limit}&path=${path}&page[number]=${pagenum}&sorting[field]=${order}&sorting[order]=${sort}`;
            if(searchTerm) {
                url = `${url}&search=${searchTerm}`;
            }
            const dam_images = await fetchWrapper.get(url);
            return {
                filemount_id: filemount_id,
                images: dam_images['data'][filemount_id]['data'],
                baseUrl: dam_images['data'][filemount_id]['base_url'],
                totalPages: dam_images['data'][filemount_id]['meta']['totalPages'],
                totalResult: dam_images['data'][filemount_id]['meta']['totalResult'],
                currentPage: pagenum
            };
        },
        getFilemountById(filemount_id) {
            let parts = filemount_id.split("-");
            for(let filemount_name in this.filemount[parts[0]]) {
                if(this.filemount[parts[0]][filemount_name]['id'] == filemount_id) {
                    return this.filemount[parts[0]][filemount_name];
                }
            }
            console.warn("Filemount not found");
        }
    }
});