<template>
<div class="modal fade" ref="modal"  tabindex="-1" role="dialog" data-backdrop="static">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content" ref="modalcontent">
            <div class="modal-body">
                <selection v-if="currentStep == 0 && !props.single"  v-model="publishConfig" v-bind="currentStepProps" ref="selectionStep"></selection>
                <component v-else v-model="publishConfig" :is="currentStepComponent" v-bind="currentStepProps" :key="currentStep" ref="configStep"></component>
                <Navigator :steps="steps" v-model="currentStep" @action="manageAction" />
            </div>
        </div>
    </div>
</div>
</template>
<script setup>

import { computed, ref, defineAsyncComponent, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useNotification } from '@kyvg/vue3-notification';
import { usePublishedStore, useNewsPaperStore } from '@/stores';
import { useLoading } from '@/loader';
import Navigator from './components/Navigator.vue';
import selection from './components/steps/selection.vue'
import config from '@/components/newspaper-republish/components/steps/config.vue'

const { t } = useI18n({});
const { notify }  = useNotification();
const loader = useLoading();

const publishedStore = usePublishedStore();
const newsPaperStore = useNewsPaperStore();

const selectionStep = ref(null);
const configStep = ref(null);

const props = defineProps({
    show: {
        type: Boolean,
        default: false
    },
    newspaper_code: {
        type: String,
        default: ''
    },
    remote_id: {
        type: String,
        default: ''
    },
    single: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['modal-action'])


const steps = computed( () => {
    let confSteps = [];
    if(!props.single) {
        confSteps.push('selection')
    }
    const computedSteps = confSteps.concat([
        ...publishConfig.value.selectedNewspapers.map( (value) => {
            return 'config|' + value
        }),
        //'finish'
    ])
    return computedSteps;
});

const publishConfig = ref({
    selectedNewspapers: [],
    newsTypeSelected: [],
    categories: [],
    positions: [],
    remote_ids: [],
    ignoreNewspapers: [],
    publishing: {}
});

const selectedNewspapers = ref([]);

const currentStep = ref(0);

const currentStepComponent = computed(
    () => {
        const index = currentStep.value;
        if(steps.value.length > index) {
            const componentParts = steps.value[index].split('|');
            if(componentParts[0] == 'config') {
                return defineAsyncComponent(() => import('./components/steps/config.vue'));
            } else {
                return defineAsyncComponent(() => import(componentPath));
            }
        }
    }
);

const currentStepProps = computed( () => {
    const index = currentStep.value;
    let props = {};
    if(steps.value.length > index) {
        const componentParts = steps.value[index].split('|');
    
        if(componentParts.length == 2) {
            props = {
                newspaperCode: componentParts[1]
            }
        }
    }
    return props;
})

const isStepValid = () => {
    if(currentStep.value == 0) {
        return selectionStep.value.isValid();
    } else if(currentStep.value < steps.value.length - 1) {
        return configStep.value.isValid();
    } else {
        return true;
    }
}

function manageAction(actionName) {
    if(actionName == 'next-step') {
        if(isStepValid()) {
            preparePublishConfig();
            if(currentStep.value + 1 < steps.value.length) {
                currentStep.value = currentStep.value + 1;
            } else {
                doPublish();
            }
        }
    } else if(actionName == 'prev-step') {
        if(currentStep.value - 1 >= 0) {
            currentStep.value = currentStep.value - 1;
        }
    } else if(actionName == 'cancel') {
        cancel();
    } else if(actionName == 'complete') {
        preparePublishConfig();
        try {
            doPublish();
        } catch(e) {
            notify({
                title: 'Error',
                text: e,
                type: 'danger'
            });
        }
    }
}

function preparePublishConfig() {
    publishConfig.value.selectedNewspapers.forEach( (val, index) => {
        if(!publishConfig.value.publishing.hasOwnProperty(val)) {
            initNewspaperData(val);
            initRemoteNewsDataFromStore(val);
        }
    });
}

function initNewspaperData(newspaperCode) {
    publishConfig.value.publishing[newspaperCode] = {};
    publishConfig.value.publishing[newspaperCode]['openedTree'] = [];
    if( newspaperCode in publishConfig.value.newsTypeSelected) {
        publishConfig.value.publishing[newspaperCode]['type'] = publishConfig.value.newsTypeSelected[newspaperCode];
    }
}

function initRemoteNewsDataFromStore(newspaperCode) {
    publishConfig.value.publishing[newspaperCode]['news'] = JSON.parse(JSON.stringify(publishedStore.remoteDetail));
    publishConfig.value.publishing[newspaperCode]['news']['uid'] = "";
    publishConfig.value.publishing[newspaperCode]['news']['categories'] = [];
    publishConfig.value.publishing[newspaperCode]['news']['related'] = false;
    publishConfig.value.publishing[newspaperCode]['news']['type'] = 0;
    if(publishConfig.value.publishing[newspaperCode]['type'] == 'linked') {
        publishConfig.value.publishing[newspaperCode]['news']['type'] = 2;
    }
    if( newspaperCode in publishConfig.value.categories ) {
        publishConfig.value.publishing[newspaperCode]['news']['categories'] = publishConfig.value.categories[newspaperCode];
        publishConfig.value.publishing[newspaperCode]['news']['uid'] = publishConfig.value.remote_ids[newspaperCode];
    }
    if( !props.single && newspaperCode != props.newspaper_code && newspaperCode in publishConfig.value.positions ) {
        publishConfig.value.publishing[newspaperCode]['news']['positions'] = publishConfig.value.positions[newspaperCode];
    }
}

function doPublish() {
    // Check all newspapers category selection
    // Go to invalid step if any

    // Remove not selected newspapers
    let publishing = {};
    publishConfig.value.selectedNewspapers.forEach( (val, index) => {
        if(publishConfig.value.publishing[val]['news']['categories'].length == 0) {
            // Invalid step
            currentStep.value = index + 1;
            throw new Error(t('select-almost-one-category'));
        }
        publishing[val] = publishConfig.value.publishing[val];
    });
    loader.show({container: modalcontent.value });
    publishConfig.value.ignoreNewspapers.forEach( (val, index) => {
        publishing[val] = 'ignore';
    });
    publishedStore.republish(publishing, props.newspaper_code, props.remote_id)
    .then( (res) => {
        done();
    }).catch( (reason) => {
        notify({
            text: "Errore nel salvataggio della notizia",
            type: "error"
        });
        console.log(reason);
    }).finally(() => {
        loader.hide();
    });
}

function done() {
    notify({
        text: "Notizia pubblicata",
        type: "success"
    });
    emit('modal-action', 'done');
}

function cancel() {
    emit('modal-action', 'close');
}

function getDetails() {
    return new Promise( (resolve, reject) => {
        publishedStore.getRemoteDetails(props.newspaper_code, props.remote_id).then( (value) => {
            const filteredNewspapers = value.data.reduce( (acc, val) => {
                if(newsPaperStore.getNewspaperName(val.backend_server_id + '-' + val.newspaper_id)) {
                    acc['selected'].push(val.backend_server_id + '-' + val.newspaper_id);
                } else {
                    acc['ignored'].push(val.backend_server_id + '-' + val.newspaper_id);
                }
                return acc;
            }, {selected: [], ignored: []});
            publishConfig.value.selectedNewspapers = filteredNewspapers['selected'];
            publishConfig.value.ignoreNewspapers = filteredNewspapers['ignored'];
            publishConfig.value.newsTypeSelected = value.data.reduce( (acc, val) => {
                acc[val.backend_server_id + '-' + val.newspaper_id] = val.linked_info;
                return acc;
            }, {});
            publishConfig.value.categories = value.data.reduce( (acc, val) => {
                acc[val.backend_server_id + '-' + val.newspaper_id] = JSON.parse(val.categories);
                return acc;
            }, {});
            publishConfig.value.positions = value.data.reduce( (acc, val) => {
                acc[val.backend_server_id + '-' + val.newspaper_id] = val.positions;
                return acc;
            }, {});
            publishConfig.value.remote_ids = value.data.reduce( (acc, val) => {
                acc[val.backend_server_id + '-' + val.newspaper_id] = val.remote_id;
                return acc;
            }, {});

            resolve();
        })
    });

}

const modal = ref(null);
const modalcontent = ref(null);

let modalPublish = null;

const initSinglePublish = () => {
    
    // Metto tutti i giornali accessibili da "ignorare"
    for( const [backend_id, newspaperList] of Object.entries(newsPaperStore.newspaper)) {
        for( const [newspaper_name, newspaper] of Object.entries(newspaperList)) {
            publishConfig.value.ignoreNewspapers.push(backend_id + '-' + newspaper['uid']);
        }
    }
    // Aggiungo quelli di pubblicazione (se non già presenti)
    publishedStore.getRemoteDetails(props.newspaper_code, props.remote_id).then( (value) => {
        value.data.forEach((newspaper) => {
            publishConfig.value.ignoreNewspapers.push(newspaper.backend_server_id + '-' + newspaper.newspaper_id)
        } );
        // Rimuovo duplicati
        publishConfig.value.ignoreNewspapers = [...new Set(publishConfig.value.ignoreNewspapers)];
        // Rimuovo quello corrente
        const removeIndex = publishConfig.value.ignoreNewspapers.indexOf(props.newspaper_code);
        if(removeIndex > -1) {
            publishConfig.value.ignoreNewspapers.splice(removeIndex, 1);
        }
        // Aggiungo solo quello corrente nei selezionati
        publishConfig.value.selectedNewspapers = [props.newspaper_code];

        publishConfig.value.newsTypeSelected = value.data.reduce( (acc, val) => {
            acc[val.backend_server_id + '-' + val.newspaper_id] = val.linked_info;
            return acc;
        }, {});
        publishConfig.value.categories = value.data.reduce( (acc, val) => {
            acc[val.backend_server_id + '-' + val.newspaper_id] = JSON.parse(val.categories);
            return acc;
        }, {});
        publishConfig.value.remote_ids = value.data.reduce( (acc, val) => {
            acc[val.backend_server_id + '-' + val.newspaper_id] = val.remote_id;
            return acc;
        }, {});

        // Imposto i dati
        initNewspaperData(props.newspaper_code);
        initRemoteNewsDataFromStore(props.newspaper_code);

        //currentStep.value = 1;
        modalPublish.show();
    });
}

const initMultiplePublish = () => {
    currentStep.value = 0;
    getDetails().then( () => modalPublish.show());
}

onMounted(() => {
    modalPublish = new bootstrap.Modal(modal.value);
    $(modalPublish._element).on("hide.bs.modal", cancel);
    watch(() => props.show, (value) => {
        currentStep.value = 0;
        if (value) {
            if(props.single == true) {
                initSinglePublish();
            } else {
                initMultiplePublish();
            }
            
        }
        else {
            modalPublish.hide();
        }
    });
});

</script>
