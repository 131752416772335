<template>
    <div class="float-right">
        <button class="btn btn-secondary" @click="cancel()" title="Annulla"><i class="fa-solid fa-xmark"></i></button>
        <button v-if="!isFirstStep" class="btn btn-secondary ml-1" @click="prevStep()">Indietro</button>
        <button v-if="!isLastStep" class="btn ml-1 btn-primary" @click="nextStep()">Avanti</button>
        <button class="btn ml-1 btn-success" @click="completeSteps()">Pubblica</button>
    </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    steps: {
        type: Array,
        default: []
    },
    isValid: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['action']);

const model = defineModel();

const isLastStep = computed( () => {
    return model.value == props.steps.length - 1;
});

const isFirstStep = computed( () => {
    return model.value == 0
});

const completeSteps = () => {
    emit('action', 'complete');
}

const nextStep = () => {
    emit('action', 'next-step');
}

const prevStep = () => {
    emit('action', 'prev-step');
}

const cancel = () => {
    emit('action', 'cancel');
}

</script>