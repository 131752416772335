<template>
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>News</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
            <li class="breadcrumb-item active">News</li>
          </ol>
        </div>
      </div>

      <div class="row" v-if="selectedNewspaper !== ''">
        <div class="col-sm-6 mb-2">
          <button v-if="!selectedNewspaper.endsWith('-0')" type="button" class="btn btn-success" @click.prevent="addDraft"><i class="fas fa-plus"></i> Nuova news</button>
        </div>
        <div class="col-sm-6 mb-2">
          <!--
          <div class="input-group ">
            <input type="search" id="search" v-model="searchTerm" class="form-control" @search="clickSearch(true)">
            <div class="input-group-prepend">
              <button class="btn btn-primary" @click.prevent="clickSearch(true)"><i class="fas fa-search"></i></button>
            </div>
          </div>
          -->
          
          <div class="input-group input-group-md">
              <div v-if="currentCategories != null && isActiveTab('published-news')" class="input-group-prepend " style="max-width:50%">
              
                <CategorySelect v-model="selectedCategoryFilter" class="form-control rounded-0" :categories="currentCategories">

                  <!-- option ><a href="#">Tutte le categorie</a></option>
                  <option ><a href="#">Sommario</a></option>
                  <option ><a href="#">- Cronaca</a></option>
                  <option ><a href="#">- Politica</a></option>
                  <option ><a href="#">- Eventi</a></option>
                  <option ><a href="#">- Sport</a></option>
                  <option ><a href="#">-- Calcio</a></option>
                  <option ><a href="#">-- Rugby</a></option>
                  <option ><a href="#">-- Volley</a></option>
                  <option ><a href="#">Rubriche</a></option>
                  <option ><a href="#">- L'oroscopo di Corinne</a></option>
                  <option ><a href="#">- Il punto di Beppe Gandolfo</a></option>
                  <option ><a href="#">- Il bello delle donne, con Luca Demaria</a></option>
                  <option ><a href="#">Zone</a></option -->
                </CategorySelect>
              </div>
            
              <input type="search" id="search" v-model="searchTerm" class="form-control" @search="clickSearch(true)">
              <div class="input-group-append">
                <button class="btn btn-primary" @click.prevent="clickSearch(true)"><i class="fas fa-search"></i></button>
              </div>
            </div>
        </div>
      </div>
      <template v-if="availableLang && availableLang.length > 1">
        <div class="row">
          <div class="col-md-4">
            <v-select class="w-100 w-md-25" v-model="selectedLanguage" :options="availableLang" label="title"
              :reduce="(el) => el.uid" @option:selected="(language_id) => languageSelected(language_id)" :clearable="false"></v-select>
          </div>
        </div>
      </template>

      <blockquote class="quote-danger my-0" v-if="selectedNewspaper == ''">
        <h5 id="tip">Attenzione!</h5>
        <p>Per inserire una News seleziona dal menu di sinistra il giornale principale su cui verrà pubblicata</p>
      </blockquote>

    </div>
  </section>


  <section class="content">
    <div class="container-fluid">

        <div class="card card-primary card-tab">
		
            <div class="card-header p-0 pt-1">
              <ul class="nav nav-tabs border-0" id="news-tabs" role="tablist">
                <li class="nav-item">
                  <a class="nav-link" @click.prevent="setActiveTab('published-news')"
                    :class="{ 'active': isActiveTab('published-news') }" id="published-tab" href="#published-news"
                    role="tab" aria-controls="published-news" aria-selected="true">Pubblicate</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" @click.prevent="setActiveTab('draft-news')"
                    :class="{ 'active': isActiveTab('draft-news') }" id="draft-tab" href="#draft-news" role="tab"
                    aria-controls="draft-news" aria-selected="false">Bozze</a>
                </li>
              </ul>
            </div>

            <div class="card-body">
			
                <div class="dt-buttons btn-group float-right mb-3">
                    <button @click.prevent="setActiveFilter('all')"
                      :class="isActiveFilter('all') ? 'btn-primary' : 'btn-outline-secondary'"
                      class="btn btn-sm buttons-copy buttons-html5" tabindex="0" aria-controls="mostra_tutte"
                      type="button"><span>Tutte</span></button>
                    <button @click.prevent="setActiveFilter('today')"
                      :class="isActiveFilter('today') ? 'btn-primary' : 'btn-outline-secondary'"
                      class="btn btn-sm buttons-csv buttons-html5" tabindex="0" aria-controls="oggi"
                      type="button"><span>Oggi</span></button>
                    <button @click.prevent="setActiveFilter('tomorrow')"
                      :class="isActiveFilter('tomorrow') ? 'btn-primary' : 'btn-outline-secondary'"
                      class="btn btn-sm buttons-excel buttons-html5" tabindex="0" aria-controls="domani"
                      type="button"><span>Domani</span></button>
                    <button @click.prevent="setActiveFilter('scheduled')"
                      :class="isActiveFilter('scheduled') ? 'btn-primary' : 'btn-outline-secondary'"
                      class="btn btn-sm buttons-excel buttons-html5" tabindex="0" aria-controls="in_attesa"
                      type="button"><span class="fa-stack fa-1x" style="width:1em; height:1em; line-height:1em;" title="In attesa"><i class="fa-regular fa-clock fa-stack-1x"></i><i class="fa-regular fa-circle fa-stack-1x" style="color:#28a745"></i></span></button>
                    <button @click.prevent="setActiveFilter('hidden')"
                      :class="isActiveFilter('hidden') ? 'btn-primary' : 'btn-outline-secondary'"
                      class="btn btn-sm buttons-excel buttons-html5" tabindex="0" aria-controls="nascoste"
                      type="button"><span class="fa-stack fa-1x" style="width:1em; height:1em; line-height:1em;" title="Nascosta"><i class="fa-regular fa-eye fa-stack-1x"></i><i class="fa-solid fa-slash fa-stack-1x" style="color:#fb5858"></i></span></button>
                </div>
			
              <div class="tab-content">
                <div class="tab-pane fade" :class="{ 'active show': isActiveTab('published-news') }" id="published-news"
                  role="tabpanel" aria-labelledby="published-tab">
                  <table-lite :page-size="pageSize" :pageOptions="pageSizeOptions" :is-slot-mode="true" :rowClasses="tableRowClass"
                    :rows="publishedList.data" :columns="publishedTableCols" :total="publishedList.total"
                    @do-search="doSearchPublished" :sortable="{}" ref="tablePublished" id="tablePublished">
                    <template v-slot:title="data">
                      <template v-if="data.value.published == 0">
                        <span @click="showNewsDetail(data.value)">{{ data.value.title }}</span>
                      </template>
                      <template v-else>
					  
                        <span @click="showNewsDetail(data.value)">
						
                          <span v-if="((parseInt(data.value.hidden) > 0) && (Date.parse(data.value.datetime) > Date.now()) )" class="fa-stack fa-1x mr-1" style="width:1em; height:1em; line-height:1em;" title="Nascosta e In attesa">
                            <i class="fa-regular fa-clock fa-stack-1x" ></i>
                            <i class="fa-solid fa-slash fa-stack-1x" style="color:#fb5858"></i>
                          </span>
                          
                          <span v-else-if="(parseInt(data.value.hidden) > 0)" class="fa-stack fa-1x mr-1" style="width:1em; height:1em; line-height:1em;" title="Nascosta">
                            <i class="fa-regular fa-eye fa-stack-1x"></i>
                            <i class="fa-solid fa-slash fa-stack-1x" style="color:#fb5858"></i>
                          </span>
                          
                          <span v-else-if="(Date.parse(data.value.datetime) > Date.now())" class="fa-stack fa-1x mr-1" style="width:1em; height:1em; line-height:1em;" title="In attesa">
                            <i class="fa-regular fa-clock fa-stack-1x"></i>
                            <i class="fa-regular fa-circle fa-stack-1x" style="color:#28a745"></i>
                          </span>
                          
                          <i v-else-if="(Date.parse(data.value.datetime) < Date.now())" class="fa-regular fa-eye fa-1x mr-1" style="color:#28a745;" title="Visibile"></i>
							
						            </span>
							
						<span @click="showNewsDetail(data.value)"> {{ data.value.title }}</span>
                      </template>
                    </template>
                    <template v-slot:actions="data">
                    <div class="text-center">
                      <div class="btn-group">
                        <i class="fa fa-edit mr-1" @click="showNewsDetail(data.value)"></i>
                        <!-- i class="fa fa-link" @click="showPublishedLinks(data.value)"></i -->
                        <i class="fa fa-link mr-1" @click="getPublishedUrls(data.value)"></i>
                        <i class="fa fa-trash mr-1" @click.prevent="confirmDeletePublished(data.value)"></i>
                        <i class="fa fa-copy" @click.prevent="confirmCloneNews(data.value)"></i>
                      </div>
                    </div>
                    
                    </template>
                  </table-lite>
                </div>
                <div class="tab-pane fade" :class="{ 'active show': isActiveTab('draft-news') }" id="draft-news"
                  role="tabpanel" aria-labelledby="draft-tab">
                  <table-lite :page-size="pageSize" :pageOptions="pageSizeOptions" :is-slot-mode="true" :rowClasses="tableRowClass"
                    :rows="draftList.data" :columns="draftTableCols" :total="draftList.total" @do-search="doSearchDraft"
                    :sortable="{}" ref="tableDraft" id="tableDraft">
                    <template v-slot:title="data">
                    
					<span @click="showNewsDetail(data.value)">
					
						<span v-if="((parseInt(data.value.hidden) > 0) && (Date.parse(data.value.datetime) > Date.now()) )" class="fa-stack fa-1x mr-1" style="width:1em; height:1em; line-height:1em;" title="Nascosta e In attesa">
							<i class="fa-regular fa-clock fa-stack-1x" ></i>
							<i class="fa-solid fa-slash fa-stack-1x" style="color:#fb5858"></i>
						</span>
						
						<span v-else-if="(parseInt(data.value.hidden) > 0)" class="fa-stack fa-1x mr-1" style="width:1em; height:1em; line-height:1em;" title="Nascosta">
							<i class="fa-regular fa-eye fa-stack-1x"></i>
							<i class="fa-solid fa-slash fa-stack-1x" style="color:#fb5858"></i>
						</span>
						
						<span v-else-if="(Date.parse(data.value.datetime) > Date.now())" class="fa-stack fa-1x mr-1" style="width:1em; height:1em; line-height:1em;" title="In attesa">
							<i class="fa-regular fa-clock fa-stack-1x"></i>
							<i class="fa-regular fa-circle fa-stack-1x" style="color:#28a745"></i>
						</span>
						
						<i v-else-if="(Date.parse(data.value.datetime) < Date.now())" class="fa-regular fa-eye fa-1x mr-1" style="color:#28a745;" title="Visibile"></i>
						
						<!--
						<i v-if="((parseInt(data.value.hidden) > 0) && (Date.parse(data.value.datetime) > Date.now()) )" class="fas fa-calendar-times mr-1" title="Nascosta e In attesa"></i>
						<i v-else-if="(parseInt(data.value.hidden) > 0)" class="fa fa-eye-slash mr-1" title="Nascosta"></i>
						<i v-else-if="(Date.parse(data.value.datetime) > Date.now())" class="fa fa-clock mr-1" title="In attesa"></i>
						-->
					
					</span>
					
                    <span @click="showNewsDetail(data.value)">{{ data.value.title }}</span>
					
                    </template>
                    <template v-slot:actions="data">
                      <template v-if="data.value.published == 0">
                        <div class="text-center">
                          <div class="btn-group">
                            <i class="fa fa-edit mr-1" @click="showNewsDetail(data.value)"></i>
                            <i class="fa fa-trash" @click.prevent="confirmDelete(data.value.uuid)"></i>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="text-center">
                          <div class="btn-group">
                            <i class="fa fa-link mr-1" @click="showPublishedLinks(data.value)"></i>
                            <i class="fa fa-eye" @click="showNewsDetail(data.value)"></i>
                          </div>
                        </div>
                      </template>
                    </template>
                  </table-lite>
                </div>
              </div>

            </div>
			
        </div>

    </div>
  </section>

  <div class="modal fade" id="modal-confirm" ref="confirm" role="dialog" data-keyboard="false">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Attenzione</h4>
        </div>
        <div class="modal-body">
          <p>Vuoi confermare l'eliminazione della bozza?</p>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-default" data-dismiss="modal">Annulla</button>
          <button type="button" class="btn btn-danger" @click="deleteDraft()">Elimina</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal-confirm-cloning" ref="confirmCloning" role="dialog" data-keyboard="false">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Attenzione</h4>
        </div>
        <div class="modal-body">
          <p>Vuoi confermare la duplicazione della notizia?</p>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-default" data-dismiss="modal">Annulla</button>
          <button type="button" class="btn btn-danger" @click="cloneNews()">Duplica</button>
        </div>
      </div>
    </div>
  </div>
  

  <div class="modal fade" id="modal-confirm-published" ref="confirmPublished" role="dialog" data-keyboard="false">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Attenzione</h4>
        </div>
        <div class="modal-body">
          <p>Vuoi confermare l'eliminazione della notizia?</p>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-default" data-dismiss="modal">Annulla</button>
          <button type="button" class="btn btn-danger" @click="deletePublished()">Elimina</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Published Links -->
  <div class="modal fade" id="pubblicazioni" ref="pubblicazioni" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Elenco pubblicazioni</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <template v-if="draftDetail" v-for="news in draftDetail.publishednews">
            <p class="mb-3"
              v-if="(newspaper = newsPaperStore.getNewspaperById(news.backend_server_id + '-' + news.newspaper_id))">
              <strong>{{ newspaper.name }}: </strong> {{ news.news_link }}
            </p>
          </template>
          <p v-if="draftDetail" class="mb-3">{{ draftDetail.public_url }}</p>
          <template v-else-if="publishedLinks" v-for="publishedLink in publishedLinks">
            <p class="mb-3"
              v-if="(newspaper = newsPaperStore.getNewspaperById(publishedLink.backend_server_id + '-' + publishedLink.newspaper_id))">
              <strong>{{ newspaper.name }}: </strong> {{ publishedLink.link }}
            </p>
          </template>
          
          <!--
          <div class="bd-code-snippet card">
            <div class="highlight card-body">
              <a href="#" class="float-right btn-tool"><i class="fa-solid fa-copy"></i></a>
              <pre tabindex="0" class="p-0 m-0">
                <code class="text-muted">
                  https://www......
                </code>
              </pre>
            </div>
          </div>
          -->
          
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
tr {
  cursor: pointer;
}
tr.news-external {
    background-color: rgba(251, 88, 88, 0.1);
}
tr.bg-muted {
    background-color: rgba(0, 0, 0, .03);
}
</style>


<script setup>
import '@vuepic/vue-datepicker/dist/main.css'

import { storeToRefs } from "pinia";
import { useDraftStore, useNewsPaperStore, useUsersStore } from '@/stores';
import TableLite from '@/components/table-lite';
import { format, parseJSON } from 'date-fns';
import { useRouter } from 'vue-router';
import { computed, onMounted, ref, onBeforeMount } from 'vue'
import { useLoading } from '@/loader';
import { useNotification } from "@kyvg/vue3-notification";
import { usePublishedStore } from '../../stores';
import { useAuthStore } from '@/stores/auth.store';
import CategorySelect from '@/components/category-select';

//import { hide, show } from '@/components/uspin/main';

const draftStore = useDraftStore();
const newsPaperStore = useNewsPaperStore();
const publishedStore = usePublishedStore();
const usersStore = useUsersStore();
const authStore = useAuthStore();

const router = useRouter();
const loader = useLoading();
const { notify } = useNotification();
const { draftList } = storeToRefs(draftStore);
const { publishedList } = storeToRefs(publishedStore);
const { selectedNewspaper } = storeToRefs(newsPaperStore);

// Template Refs
const confirm = ref(null);
const confirmPublished = ref(null);
const confirmCloning = ref(null);
const pubblicazioni = ref(null);
const tableDraft = ref(null);
const tablePublished = ref(null);

const selectedLanguage = ref(0);
publishedStore.selectedLanguage = ref(selectedLanguage);
draftStore.selectedLanguage = ref(selectedLanguage);

// Reactive state
const deletingDraft = ref(Number);
let deletingNews = {
  'newspaper_id': null,
  'news_id': null
};
let cloningNews = {
  'newspaper_id': null,
  'news_id': null
};

const draftDetail = ref(null);
const searchTerm = ref("");
const draftCurrentPage = ref(0);
const publishedCurrentPage = ref(0);


/**
 * BEGIN: Category Filter
 */
const selectedCategoryFilter = ref(0);

const currentCategories = computed( () => {
  const parts = selectedNewspaper.value.split("-");
  const backendId = Number.parseInt(parts[0]);
  const newspaperId = Number.parseInt(parts[1]);
  if( newspaperId > 0) {
    const newspaper = newsPaperStore.getNewspaperById(selectedNewspaper.value);
    return newspaper.categories;
  }
  return null;
});


/**
 * END: Category Filter
 */


const publishedLinks = ref(null);

let fetchingDraft = false;
let fetchingPublished = false;

let modalConfirm;
let modalConfirmPublished;
let modalConfirmCloning;
let modalPublishedLinks;
const dateFormat = "dd/MM/yyyy HH:mm";

var publishedTableCols = [
  {
    label: "Titolo",
    width: "45%",
    field: "title"
  }, {
    label: "Data",
    width: "10%",
    field: "datetime",
    display: (row) => { return formatDateTime(row.datetime); }
  },{
    label: "Categorie",
    width: "10%",
    field: "categories",
    display: (row) => { return displayCategories(row); }
  },{
    label: "Posizione",
    width: "10%",
    field: "positions",
    display: (row) => { return displayPosition(row.positions); }
  }, {
    label: "Autore",
    width: "10%",
    field: "author",
    display: (row) => { return displayAuthor(row); }
  }, { 
    label: "Giornale",
    width: "10%",
    field: "newspaper",
    display: (row) => { return newsPaperStore.getNewspaperName(row.newspaper_id) }
  }, {
    label: "",
    width: "5%",
    field: "actions"
  },
];


var draftTableCols = [
  { 
    label: "Titolo",
    width: "55%",
    field: "title"
  }, {
    label: "Data",
    width: "10%",
    field: "datetime",
    display: (row) => { return formatDateTime(row.datetime) }
  }, {
    label: "Posizione", 
    width: "10%", 
    field: "positions", 
    display: (row) => { return displayPosition(row.positions); }
  }, {
    label: "Autore",
    width: "10%",
    field: "author",
    display: (row) => { return row.author; }
  }, { 
    label: "Giornale", 
    width: "10%", 
    field: "newspaper", 
    display: (row) => { return newsPaperStore.getNewspaperName(row.newspaper_id) }
  }, { 
    label: "", 
    width: "5%", 
    field: "actions"
  },
];

if(!selectedNewspaper.value.endsWith("-0")) {
  publishedTableCols.splice(5,1);
  draftTableCols.splice(4,1);
}

onBeforeMount(() => {
  searchTerm.value = "";
  selectedCategoryFilter.value = 0;
  //restoreStatusFromStore();
});

onMounted(() => {
  if(!selectedNewspaper.value.endsWith("-0")) {
    clickSearch();
  } else {
    publishedList.value = ref([]);
    draftList.value = ref([]);
  }
  // Initialize delete confirmation modal
  modalConfirm = new bootstrap.Modal(confirm.value);
  modalConfirm._config.backdrop = "static";
  modalConfirm._config.keyboard = false;
  $(modalConfirm._element).on("hide.bs.modal", cancelDelete);

  modalConfirmPublished = new bootstrap.Modal(confirmPublished.value);
  modalConfirmPublished._config.backdrop = "static";
  modalConfirmPublished._config.keyboard = false;
  $(modalConfirmPublished._element).on("hide.bs.modal", cancelDeletePublished);


  modalConfirmCloning = new bootstrap.Modal(confirmCloning.value);
  modalConfirmCloning._config.backdrop = "static";
  modalConfirmCloning._config.keyboard = false;
  $(modalConfirmCloning._element).on("hide.bs.modal", cancelCloning);
  

  modalPublishedLinks = new bootstrap.Modal(pubblicazioni.value);
  modalPublishedLinks._config.backdrop = "static";
  modalPublishedLinks._config.keyboard = false;
});


const availableLang = computed(() => {
  const langs = Object.values(newsPaperStore.getNewspaperLangs(newsPaperStore.selectedNewspaper));
  return langs;
});

const pageSizeOptions = [
  { 
    value: 10, 
    text: 10 
  }, {
    value: 25, 
    text: 25 
  }, { 
    value: 50, 
    text: 50 
  }, { 
    value: 100, 
    text: 100 
  }
];

const pageSize = computed({
  get() {
    return authStore.user?.preferences.lastPreferences.news.newsPageSize;
  },
  set(value) {
    if(authStore.user.preferences.lastPreferences.news.newsPageSize != value) {
      authStore.user.preferences.lastPreferences.news.newsPageSize = value;
      authStore.updatePreferences(authStore.user);
      doSearch();
    }
  }
});

const quickFilter = computed({
  get() {
    return authStore.user?.preferences.lastPreferences.news.quickFilter;
  },
  set(value) {
    if(authStore.user.preferences.lastPreferences.news.quickFilter != value) {
      authStore.user.preferences.lastPreferences.news.quickFilter = value;
      authStore.updatePreferences(authStore.user);
      doSearch();
    }
  }
});

function doSearch() {
  if (newsPaperStore.selectedNewspaper) {
    if (isActiveTab('draft-news')) {
      if (tableDraft.value.setting.page == 1) {
        doSearchDraft(0, pageSize.value);
      } else {
        tableDraft.value.setting.page = 1;
      }
    } else if (isActiveTab('published-news')) {
      if (tablePublished.value.setting.page == 1) {
        doSearchPublished(0, pageSize.value);
      } else {
        tablePublished.value.setting.page = 1;
      }
    }
  } else {
    console.log("No newspaper selected");
  }
}


function clickSearch(force=false) {
  if (force || !newsPaperStore.selectedNewspaper.endsWith("-0")) {
    doSearch();
  }
}

function doSearchDraft(offset, limit, order, sort) {
  draftCurrentPage.value = offset;
  if (fetchingDraft == false && newsPaperStore.selectedNewspaper && isActiveTab('draft-news')) {
    fetchingDraft = true;
    if (limit != pageSize.value) {
      pageSize.value = limit;
      //updateStatusInStore();
    }
    loader.show();
    draftStore.fetchDraftNewspaper(router.currentRoute.value.params.newspaperid, offset, limit, searchTerm.value, quickFilter.value)
      .catch((reason) => {
        notify({
          title: "Error",
          text: reason,
          type: 'danger',
        });
      }).finally(() => {
        loader.hide();
        fetchingDraft = false;
      });
  }
}

function doSearchPublished(offset, limit, order, sort) {
  publishedCurrentPage.value = offset;
  if (fetchingPublished == false && newsPaperStore.selectedNewspaper && isActiveTab('published-news')) {
    fetchingPublished = true;
    if (limit != pageSize.value) {
      pageSize.value = limit;
      //updateStatusInStore();
    }
    loader.show();

    publishedStore.fetchRelatingRemote(router.currentRoute.value.params.newspaperid, offset, limit, searchTerm.value, [], "", selectedLanguage.value, quickFilter.value, selectedCategoryFilter.value).then((value) => {
      publishedList.value = value;
    }).catch((reason) => {
      notify({
        title: "Error",
        text: reason,
        type: 'error',
      });
    }).finally(() => {
      loader.hide();
      fetchingPublished = false;
    });
  }
}

function formatDateTime(inputDate) {
  let date;
  try {
    date = format(parseJSON(inputDate), dateFormat);
  } catch (err) {
    date = '';
  }
  return date;
}

function displayPosition(inputPositions) {
  // Inizializza un array vuoto dove verranno salvati i nomi delle posizioni
  let positions = [];
  // Cicla sulla definizione delle posizioni: se è presente in "inputPositions" (quelle sulla notizia),
  // aggiunge il nome della posizione nell'array "positions"
  newsPaperStore.positions.forEach(element => {
    if (inputPositions.includes(element.id)) {
      positions.push(element.name);
    }
  });
  // Ritorna il join dell'array usando come separatore "virgola + spazio"
  return positions.join(', ');
}

function displayAuthor(row) {
  //debugger;
  const author_id = parseInt(row.tx_ttnewsauthor_render);
  if(author_id > 0) {
    const author = newsPaperStore.getAuthorById(newsPaperStore.selectedNewspaper, author_id)
    return author?.label;
  }
  return row.author;
}

function displayCategories(row) {
  //debugger;
  if(row.categories) {
    let parts = row.newspaper_id.split('-');
  
    const catNames = row.categories.map( (value) => {
      return newsPaperStore.getCategoryName(parts[0], parts[1], value)
    });

    return catNames.filter(e=>e).join(', ');
  }
  console.log("No categories", row);
  return "";
}

function confirmCloneNews(news) {
  cloningNews = {
    'newspaper_id': news.newspaper_id,
    'news_id': news.uid
  };
  modalConfirmCloning.show();
}

function confirmDelete(draftId) {
  deletingDraft.value = draftId;
  modalConfirm.show();
}

function confirmDeletePublished(news) {
  deletingNews = {
    'newspaper_id': news.newspaper_id,
    'news_id': news.uid
  };
  modalConfirmPublished.show();
}

function cancelCloning() {
  cloningNews = {
    'newspaper_id': null,
    'news_id': null
  };
}

function cancelDeletePublished() {
  deletingNews = {
    'newspaper_id': null,
    'news_id': null
  };
}

function cancelDelete() {
  deletingDraft.value = null;
}

function getPublishedUrls(data) {
  newsPaperStore.getPublishedUrls(data.newspaper_id, data.uid)
    .then( (value) => {
      console.log(value);
      publishedLinks.value = value.data;
      draftDetail.value = null;
      modalPublishedLinks.show();
    })
    .catch( (reason) => {
      notify({
        title: "Error",
        text: reason,
        type: 'danger',
      });
    });
}

async function cloneNews() {
  const news = cloningNews;
  modalConfirmCloning.hide();
  loader.show();
  newsPaperStore.duplicateNews(news['newspaper_id'], news['news_id']).then((res) => {
    router.push({ name: 'draft-edit', params: { newspaperid: news['newspaper_id'], uuid: res.data.uuid } });
  }).catch((reason) => {
    notify({
      title: "Error",
      text: reason,
      type: 'danger',
    });
  }).finally(() => {
    cloningNews = {
      'newspaper_id': null,
      'news_id': null
    };
    loader.hide();
  });
}

async function deletePublished() {
  const news = deletingNews;
  modalConfirmPublished.hide();
  loader.show();
  // TODO
  newsPaperStore.deletePublished(news['newspaper_id'], news['news_id']).then((res) => {
    searchTerm.value = "";
    doSearchPublished(publishedCurrentPage.value, pageSize.value);
  }).catch((reason) => {
    notify({
      title: "Error",
      text: reason,
      type: 'danger',
    });
    loader.hide();
  }).finally(() => {
    deletingNews = {
      'newspaper_id': null,
      'news_id': null
    };
  });
}

async function deleteDraft() {
  const draftId = deletingDraft.value;
  modalConfirm.hide();
  loader.show();
  draftStore.deleteDraft(draftId).then((res) => {
    searchTerm.value = "";
    doSearchDraft(draftCurrentPage.value, pageSize.value);
    //draftStore.fetchDraftNewspaper(router.currentRoute.value.params.newspaperid);
  }).catch((reason) => {
    notify({
      title: "Error",
      text: reason,
      type: 'danger',
    });
  }).finally(() => {
    deletingDraft.value = null;
    loader.hide();
  });

}

function addDraft() {
  draftStore.resetLocalStore();
  router.push({ path: router.currentRoute.value.fullPath + '/new' });
}

function showNewsDetail(news) {
  console.log("newsPaperStore.selectedNewspaper", news.newspaper_id);
  newsPaperStore.selectedNewspaper = news.newspaper_id;
  if (news.uid) {
    router.push({ name: 'remote-edit', params: { newspaperid: news.newspaper_id, id: news.uid } });
  } else {
    router.push({ name: 'draft-edit', params: { newspaperid: news.newspaper_id, uuid: news.uuid } });
  }
  return;
}

function showPublishedLinks(news) {
  draftDetail.value = news;
  modalPublishedLinks.show();
}

function languageSelected(language_id) {
  //doSearch(0, tableDraft.value.setting.pageSize);
  clickSearch();
}

function tableRowClass(row) {
  let classes = [];
  if (row.hidden == 1 || Date.parse(row.datetime) > Date.now()) {
    classes.push('text-muted bg-muted');
  }
  if(row.type == 2) {
    classes.push('news-external');
  }
  return classes.join(" ");
}


// Nav Tabs
function isActiveTab(tabname) {
  if(authStore.user) {
    if(!authStore.user.preferences.tab) {
      return authStore.user.preferences.startingTab == tabname;
    }
    return authStore.user.preferences.tab == tabname;
  }
  return 'none';
}

function setActiveTab(tabname) {
  authStore.user.preferences.tab = tabname;
  if (newsPaperStore.selectedNewspaper) {
    clickSearch();
  } else {
    console.log("No selected newspaper");
  }
}

function isActiveFilter(filtername) {
  return quickFilter.value === filtername;
}

function setActiveFilter(filtername) {
  if (filtername != quickFilter.value) {
    quickFilter.value = filtername;
    //updateStatusInStore();
    clickSearch();
  }
}

/*
function restoreStatusFromStore() {
  hashStatus.value = usersStore.uiStatus.news;
}

function updateStatusInStore() {
  usersStore.uiStatus.news = hashStatus.value;
}
*/
</script>
