import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Bold, Italic, Underline } from '@ckeditor/ckeditor5-basic-styles';
import { Link } from '@ckeditor/ckeditor5-link';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { SourceEditing } from '@ckeditor/ckeditor5-source-editing';
import { List } from '@ckeditor/ckeditor5-list';
import { Table, TableToolbar, TableProperties, PlainTableOutput } from '@ckeditor/ckeditor5-table';
import { Image, ImageCaption, ImageResize, ImageStyle, ImageToolbar, ImageUpload, ImageBlock, ImageTextAlternative } from '@ckeditor/ckeditor5-image';
//import { HtmlEmbed } from '@ckeditor/ckeditor5-html-embed/src';
import { ImageEditor } from '@/ckeditor5/plugins/edit-image';
import { MediaEmbed } from '@ckeditor/ckeditor5-media-embed';
import { DamImagePlugin } from '@/ckeditor5/plugins/dam-image';
import PublisherUploadAdapterPlugin from '@/ckeditor5/plugins/image-upload';
import { RteLink } from '@/ckeditor5/plugins/rte-link';
import { RteVideo } from '@/ckeditor5/plugins/rte-video';
import { RteImage } from '@/ckeditor5/plugins/rte-image';
import { PublisherUploadAdapter } from '@/ckeditor5/plugins/image-upload';
import '@/ckeditor5/translations/it';
import { HtmlEmbed } from '@ckeditor/ckeditor5-html-embed'; 

const editorConfig = {
    language: 'it',
    plugins: [HtmlEmbed, ImageTextAlternative, ImageEditor, ImageToolbar, MediaEmbed, Heading, Essentials, Bold, Italic, Underline, Link, Paragraph, Table, TableToolbar, TableProperties, SourceEditing, List, Image, ImageStyle,  ImageResize,  ImageUpload, DamImagePlugin, PublisherUploadAdapter ],
    extraPlugins: [ PublisherUploadAdapterPlugin, RteLink, RteVideo, RteImage, PlainTableOutput ],
    toolbar: {
        items: [ 'heading', 'bold', 'italic', 'underline', 'sourceEditing', 'bulletedList', 'numberedList',  'link', 'undo', 'redo',  'insertTable',
        '|',
        'uploadImage', 'dam-image', 'mediaEmbed', '|', 'htmlEmbed' ]
    },
    table: {
        contentToolbar: [ 'tableColumn', 'tableRow', 'tableProperties' ]
    },
    'heading': {
        options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading4' }
        ]
    },
    'image': {
        'insert': {
            'type': 'inline',
            'integrations': ['dam-image']
        },
        'toolbar': ['imageTextAlternative', 'ImageEditor']
    },
    'dam-image': {
        click: null,
    },
    'dam-edit': {
        click: null
    },
    'mediaEmbed': {
        'elementName': 'div',
        'previewsInData': true,
        'providers': [
            {
                name: 'facebook',
                url: [
                    /^https:\/\/(www\.)?facebook\.com\/.+\/videos\/.*?([0-9]+)/,
                    /^https:\/\/(www\.)?facebook\.com\/watch\/\?v=([0-9]+)/,
                    /^https:\/\/fb.watch\/([^\/?]+)/
                ],
                html: match => {
                    const id = encodeURIComponent(match[2]);
                    const url = encodeURIComponent(match[0]);
                    return (`<span class="video-embed fb-video video-container" data-href="https://www.facebook.com/watch/?v=${id}" data-type="facebook" width="425"><iframe src="https://www.facebook.com/plugins/video.php?height=245&href=${url}&show_text=false&width=425&t=0" width="425" height="349" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe></span>`)
                }
            }, {
                name: 'youtube',
                url : [
                    /^https?:\/\/www\.youtube\.com\/watch\?v=([^=&]+)/,
                    /^https:\/\/youtu.be\/([^\/?]+)/,
                    /^https?:\/\/www\.youtube\.com\/shorts\/([a-zA-Z0-9]+)/,
                    /^https?:\/\/youtube\.com\/shorts\/([a-zA-Z0-9]+)/
                ],
                html: match => {
                    const id = encodeURIComponent(match[1]);
                    const url = encodeURI(`https://www.youtube.com/embed/${id}`);
                    return (`<span class="video-embed video-container" data-href="https://www.youtube.com/watch?v=${id}" data-type="youtube" width="425" height="349"><iframe src="${url}" width="425" height="349" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe></span>`)
                }
            }, {
                name: 'vimeo',
                url : [
                    /https?:\/\/.*vimeo\.com\/(\d+).*/,
                ],
                html: match => {
                    const id = encodeURIComponent(match[1]);
                    const url = encodeURI(`https://player.vimeo.com/video/${id}`);
                    return (`<span class="video-embed video-container" data-href="${match[0]}" data-type="vimeo" width="425" height="349"><iframe src="${url}" width="425" height="349" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe></span>`)
                }
            }
        ]
    }
};

export default editorConfig;

