<script setup>
import { CategoryNav } from '@/components';
import { useDraftStore, usePublishedStore, useNewsPaperStore } from '@/stores';

import { useRouter } from 'vue-router';
import { ref, onMounted } from 'vue'
import { useLoading } from '@/loader';
import version from '@/version';
import { storeToRefs } from 'pinia';

const loader = useLoading();
const installation = import.meta.env.VITE_APP_INSTALLATION;

let draftStore = ref();
const router = useRouter();
if(router.currentRoute.value.path.startsWith('/draft') || router.currentRoute.value.path == "/") {
  draftStore = useDraftStore();
} else {
  draftStore = usePublishedStore();
}


function newspaperSelect(newspaper_id) {
  const newsPaperStore = useNewsPaperStore();

  const publishedStore = usePublishedStore();
  const { publishedList } = storeToRefs(publishedStore);
  // From CategoryTree
  if(newspaper_id != newsPaperStore.selectedNewspaper || router.currentRoute.value.name != "draft-list-newspaper") {
    newsPaperStore.selectedNewspaper = newspaper_id;
    router.push({ path: '/draft/newspaper/' + newspaper_id });
  }
}

</script>

<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-light-primary elevation-2">
    <!-- Brand Logo -->
    <router-link to="/" class="brand-link">
      <img src="@/assets/img/logo.png" alt="PUBLISHER" class="brand-image">
      <span class="text-primary brand-text font-weight">PUBLISHER</span>
      
      <template v-if="installation == 'DEV'">
        <sup class="bg-danger rounded" style="padding:1px 5px 2px; margin-left:5px;"><small>DEV {{ version }}</small></sup>
      </template>
      <template v-else-if="installation == 'PREVIEW'">
        <sup class="bg-warning rounded" style="padding:1px 5px 2px; margin-left:5px;"><small>BETA {{ version }}</small></sup>
      </template>
      
    </router-link>
    <!-- Sidebar -->
    <div class="sidebar">
      <CategoryNav v-if="draftStore" @newspaperselect="newspaperSelect" :newsStore="draftStore" :persist="true" />
    </div>
  </aside> 
  <div class="content-wrapper">
    <!-- List / -->
    <router-view  :key="$route.params[$route.meta.watchParam]"></router-view>
  </div>
  
  <footer class="main-footer">
    © <strong>More News</strong>
  </footer>
  

  
</template>

